import React from 'react';
import {useStoreState} from "easy-peasy";

export default function EmployeeDashboardHeader({header}){
	const displayName = useStoreState(state=>state.user.displayName);
	const lastUpdatedDate = useStoreState(state=>state.headerInfo.lastUpdated);

	return (
		<>
			{!!header && (

				<div style={{backgroundColor:header.color}} className={'dashboard-header employee full-width simple'}>
					<div className={'dashboard-header-holder max-width-container simple employee'}>
						<div className={'dashboard-header-content simple'}>
							<h1>{'Welcome back to Thrive ' + displayName}</h1>
							<p>{lastUpdatedDate !== 'hide' && header.subTitle.replace('%last_date',lastUpdatedDate)}</p>
							
							<a href={'#dashboard-details'} className={'down-anchor'} />
						</div>
						<a href={'https://calendly.com/slaadvisors/student-loan-review'} className={'simple-rounded-button green small'} target='_blank' rel='noopener noreferrer'><span>Schedule a Free <br/> federal Student Loan Coaching Session</span></a>
					</div>
				</div>
			)
			}
		</>
	)
}
